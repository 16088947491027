import { UserService } from '../../services/user.service';
import { UserModel } from '../../models/user.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AppCommunicationService } from '../../services/app-communication.service';

@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.scss'],
})
export class UserViewComponent implements OnInit {

  isUserAdmin = false;
  users: UserModel[] = [];
  expanded: any = {};
  timeout: any;
  loadingIndicator = true;
  allRowsExpanded = false;

  @ViewChild('myTable', { static: false }) table: any;

  constructor(
    private _userService: UserService,
    private _appCommunicationService: AppCommunicationService,
  ) {
  }

  ngOnInit() {
    // Pri inicializacii komponentu nacitam potrebne data
    this.loadData();
  }

  // nacitanie dat
  public loadData() {
    this._userService
      .getAllAppUsers()
      .subscribe(
        res => {
          this.users = res;
          this.loadingIndicator = false;
        },
        error2 => {
          console.log(error2);
        },
      );
  }

  onPage(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
    }, 100);
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  toggleExpandAll() {
    if (this.allRowsExpanded) {
      this.table.rowDetail.collapseAllRows();
    } else {
      this.table.rowDetail.expandAllRows();
    }
    this.allRowsExpanded = !this.allRowsExpanded;
  }

  getUserRolesAsArrayFromString(rolesAsString: string): string[] {
    const roles: string[] = [];

    rolesAsString.split(',', 500).forEach(role => {
      if (AppCommunicationService.POSSIBLE_ROLES.includes(role)) {
        roles.push(role);
      }
    });

    return roles;
  }

}
