import * as _ from 'lodash';
import {
  BASE_FRONTEND_URL,
  DEV_HOST,
  getBaseApiUrl, getBaseFrontendUrl,
  LOCAL_APP_PORTS,
  LOCAL_FRONTEND_URL,
  LOCAL_HOST,
  PROD_HOST
} from "src/app/+app-custom/app.urls";
import {
  SYSTEM_NAME_EPC,
  SYSTEM_NAME_ETD,
  SYSTEM_NAME_ISBN,
  SYSTEM_NAME_ROOMBOOK,
  SYSTEM_NAME_ZP,
  SYSTEM_NAME_UIK,
  SYSTEM_NAME_HELPDESK,
  SYSTEM_NAME_TOOLS,
  SYSTEM_NAME_LIBADMIN,
  SYSTEM_NAME_ALFRED,
  SYSTEM_NAME_HELIOS,
} from 'src/app/+app-custom/constants';
import { DEVAPI_BUILD, LOCAL_AUTH, LOCAL_DEVELOPMENT, PRODUCTION_BUILD, SYSTEM_NAME } from "../../+app-custom/app.settings";

export const AUTH_BASE_URL = getBaseApiUrl('AUTH');
const BASE_URL = getBaseApiUrl(SYSTEM_NAME);

export const AUTH_BASE_COOKIE_DOMAIN = getAuthBaseCookieDomain();

// constructs URL for auth redirection
function getAuthRedirectUrl(): string {
  if (LOCAL_AUTH) {
    return LOCAL_FRONTEND_URL;
  }

  return `${BASE_FRONTEND_URL}`;
}

// constructs base domain for cookie storage
function getAuthBaseCookieDomain(): string {
  if (PRODUCTION_BUILD) {
    return 'tuke.sk';
  } else if (!DEVAPI_BUILD) {
    return 'localhost';
  }

  return 'tuke.sk';
}

// constructs redirect URL for specified app
function getAppRedirectUrl(systemName: string): string {
  return `${LOCAL_DEVELOPMENT ? (LOCAL_FRONTEND_URL + _.toString(LOCAL_APP_PORTS[systemName])) : getBaseFrontendUrl(systemName)}`;
}

export const AUTH_REDIRECT_URL = getAuthRedirectUrl();

export const APP_URL_EPC = getAppRedirectUrl('epc');
export const APP_URL_ETD = getAppRedirectUrl(SYSTEM_NAME_ETD);
export const APP_URL_ISBN = getAppRedirectUrl(SYSTEM_NAME_ISBN);
export const APP_URL_ROOMBOOK = getAppRedirectUrl(SYSTEM_NAME_ROOMBOOK);
export const APP_URL_ZP = getAppRedirectUrl(SYSTEM_NAME_ZP);
export const APP_URL_UIK = getAppRedirectUrl(SYSTEM_NAME_UIK);
export const APP_URL_HELPDESK = getAppRedirectUrl(SYSTEM_NAME_HELPDESK);
export const APP_URL_TOOLS = getAppRedirectUrl(SYSTEM_NAME_TOOLS);
export const APP_URL_LIBADMIN = getAppRedirectUrl(SYSTEM_NAME_LIBADMIN);
export const APP_URL_ALFRED = getAppRedirectUrl(SYSTEM_NAME_ALFRED);
export const APP_URL_HELIOS = getAppRedirectUrl(SYSTEM_NAME_HELIOS);

/* ### AUTH URLS ### */
const API_LOGIN = '/auth/token';
const API_LOGOUT = '/auth/logout';
const API_GET_EMPLOYMENTS = '/auth/getEmployments';
const API_CHANGE_EMPLOYMENT = '/auth/changeEmployment';

export function getLoginUrl() {
  return `${BASE_URL}${API_LOGIN}`;
}

export function getLogoutUrl() {
  return `${BASE_URL}${API_LOGOUT}`;
}

export function getEmploymentsUrl() {
  return `${BASE_URL}${API_GET_EMPLOYMENTS}`;
}

export function changeEmploymentUrl() {
  return `${BASE_URL}${API_CHANGE_EMPLOYMENT}`;
}

export function getAppConfigurationUrl() {
  return `${BASE_URL}/config/client`;
}
