export function roundPrice(price: number): number {
  const integerPart = Math.trunc(price);
  const decimalPart = Number((price - integerPart).toFixed(2));

  // get second decimal number
  const secondDecimalNumber = Number.parseInt(((Math.abs((decimalPart * 100) / 100) * Math.pow(10, 2)) % 10).toFixed(0), 0);

  if (secondDecimalNumber && secondDecimalNumber !== 0 && secondDecimalNumber !== 5) {

    if (secondDecimalNumber >= 0 && secondDecimalNumber < 3) {
      price = roundDown(price);
    } else if (secondDecimalNumber >= 3 && secondDecimalNumber < 8) {
      price = secondDecimalNumberTo5(price, secondDecimalNumber);
    } else {
      price = roundUp(price);
    }

  }

  return price;

}

export function roundUp(value: number, decimals: number = 1): number {
  // @ts-ignore
  return ((Math.round(value * 100) / 100)).toFixed(1);
}

export function roundDown(value: number, decimals: number = 1): number {
  // @ts-ignore
  return ((Math.floor(value * 100) / 100)).toFixed(1);
}

export function secondDecimalNumberTo5(value: number, secondDecimalNumber: number): number {
  // 8.44 -> 8.45
  let num;

  const truncated = Math.trunc(value * 10) / 10;

  num = truncated.toFixed(1);

  const finalNumber = Number.parseFloat(`${num}5`);

  return finalNumber;
}
