import { ItemModel } from '../../models/item.model';
import { CategoryService } from '../../services/category.service';
import { SaveItemModel } from '../../models/save-item.model';
import { ModalDirective } from 'ngx-bootstrap';
import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { WorkplaceModel } from '../../models/workplace.model';
import { AppCommunicationService } from '../../services/app-communication.service';
import { CategoryModel } from '../../models/category.model';
import { ItemService } from '../../services/item.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-item-form',
  templateUrl: './item-form.component.html',
  styleUrls: ['./item-form.component.scss'],
})
export class ItemFormComponent implements OnInit {
  // Deklarujem premenne:
  saving = false;
  // item: je SaveResource backend model -> pomocou tejto struktury odoslem data na backend
  item: SaveItemModel;
  // categories: Zoznam kategorii pre select
  categories: CategoryModel[];
  workplaces: WorkplaceModel[];
  showWorkplacesError = false;

  // Definujem si nazov Child elementu v HTML
  @ViewChild('lgModal', { static: false }) public lgModal: ModalDirective;
  @ViewChild('f', { static: false }) public form: NgForm;

  // Definujem, co sa stane na vystupe -> vytvorim event,
  // ktory zavola v rodicovskom/parent componente metodu -> ta znovu nacita data v parente
  @Output() loadParentData = new EventEmitter();

  constructor(
    private _categoryService: CategoryService,
    private _itemService: ItemService,
    private _appCommunicationService: AppCommunicationService,
    private toastr: ToastrService,
  ) {
  }

  ngOnInit() {
    // Pri inicializacii komponentu nastavim defaultne hodnoty v modeli
    this.setDefaultItemData();
  }

  // Touto metodou zavolam modalne okno, ktore sa otvori
  showModal(inputItem: ItemModel) {
    this.saving = false;

    // nacitam kategorie do selectu a
    this._categoryService.getAllCategories()
      .subscribe(
        res => {
          this.categories = res;
        },
        error2 => {
          console.log(error2);
          this.closeModal();
        },
      );

    // Nacitam workplaces
    this._itemService.getAllWorkplaces()
      .subscribe(
        res => {
          this.workplaces = res;
          // console.log(this.workplaces );
        },
        error2 => {
          console.log(error2);
          this.closeModal();
        },
      );

    // Ak editujem existuji item, tak ho namapujem do modelu, inak nastavim defaultne hodnoty
    if (inputItem) {
      this.mapExistingItemIntoModel(inputItem);
    } else {
      this.setDefaultItemData();
    }

    // Otvorim modalne okno
    this.lgModal.show();
  }

  // Zavriem modalne okno a resetujem formular
  closeModal() {
    this.saving = false;
    this.form.resetForm();
    this.lgModal.hide();
  }

  // Potvrdenie
  confirmModal() {
    this.saving = true;
    // Vytvorim objekt alebo ulozim zmeny, podla toho, ci item.id = 0 alebo uz idcko ma
    if (this.item.id) {
      this._itemService.updateItem(this.item)
        .subscribe(item => {
            this.setDefaultItemData();
            this.closeModal();
            this.loadParentData.emit();
            this.toastr.success('Položka ' + this.item.name + ' bola úspešne upravená.', 'Úprava');
          }
          , error2 => {
            this.setDefaultItemData();
            this.closeModal();
            console.log(error2);
            this.toastr.error('Nepodarilo sa upraviť položku. Kontaktujte prosím podporu.', 'Úprava neúspešná');
          });
    } else {
      this._itemService.createItem(this.item)
        .subscribe(item => {
            this.setDefaultItemData();
            this.closeModal();
            this.loadParentData.emit();
            this.toastr.success('Položka ' + this.item.name + ' bola úspešne upravená.', 'Úprava');
          }
          , error2 => {
            this.setDefaultItemData();
            this.closeModal();
            console.log(error2);
            this.toastr.error('Nepodarilo sa upraviť položku. Kontaktujte prosím podporu.', 'Úprava neúspešná');
          });
    }
  }

  private setDefaultItemData() {
    // Aby som pri nacitani nastavil defaultne/nulove hodnoty tak, aby bol formular prazdny, aby DOM nacitalo bez chyb a pod.
    this.item = {
      id: 0,
      name: '',
      price: 0,
      categoryId: 0,
      workplaces: [],
      pluCode: 0,
      isMinPriceRequired: false,
      isOnePieceItem: false,
    };
  }

  // Existujuci item namapujem do modelu
  private mapExistingItemIntoModel(inputItem: ItemModel) {
    this.item.id = inputItem.id;
    this.item.name = inputItem.name;
    this.item.categoryId = inputItem.category.id;
    this.item.price = inputItem.price;
    this.item.pluCode = inputItem.pluCode;
    this.item.isOnePieceItem = inputItem.isOnePieceItem;
    inputItem.workplaces.forEach(workplace => {
      this.item.workplaces.push(workplace.id);
    });
  }

  onWorkplaceToggle(worplaceId: number, $event: any) {
    if ($event.target.checked) {
      this.item.workplaces.push(worplaceId);
    } else {
      const index = this.item.workplaces.indexOf(worplaceId);
      this.item.workplaces.splice(index, 1);
    }

    this.showWorkplacesError = this.item.workplaces.length === 0;
  }

  onOnePieceItemToggle($event: any) {
    if ($event.target.checked) {
      this.item.isOnePieceItem = true;
      this.item.price = 0;
      this.item.isMinPriceRequired = false;
    } else {
      this.item.isOnePieceItem = false;
    }
  }
}
