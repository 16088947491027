import { SaveCategoryModel } from '../models/save-category.model';

import { UserService } from './user.service';
import { AppCommunicationService } from './app-communication.service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CategoryModel } from '../models/category.model';
import {
  getCategoryUrl,
  getCreateCategoryUrl,
  getSoftDeleteCategoryUrl,
  getUpdateCategoryUrl,
} from '../app.urls';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../core-frontend-common/auth/authentication.service';

@Injectable()
export class CategoryService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private _userService: UserService,
    private _appCommunicationService: AppCommunicationService,
    private _authenticationService: AuthenticationService
  ) { }

  getAllCategories(): Observable<CategoryModel[]> {
    return this.getAllCategoriesApi();
  }

  getAllCategoriesWithResponsibles(): Observable<CategoryModel[]> {
    return this.getAllCategoriesApi();
  }

  private getAllCategoriesApi(): Observable<CategoryModel[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this._authenticationService.checkUser()) {
      return this.http
        .get(getCategoryUrl(), httpOptions)
        .pipe(map((res: any) => res.items as CategoryModel[]));
    }
  }

  softDeleteCategory(categoryId: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this._authenticationService.checkUser()) {
      return this.http
        .put(getSoftDeleteCategoryUrl(categoryId), null, httpOptions);
    }
  }

  createCategory(category: SaveCategoryModel): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this._authenticationService.checkUser()) {
      return this.http
        .post(getCreateCategoryUrl(), category, httpOptions)
        .pipe(map(res => {
          const categoryModel = res as CategoryModel;
          this._userService.getUserById(Number.parseInt(categoryModel.createdById.toString(), 0))
            .subscribe(res1 => categoryModel.createdBy = res1);
          this._userService.getUserById(Number.parseInt(categoryModel.updatedById.toString(), 0))
            .subscribe(res2 => categoryModel.updatedBy = res2);
          return categoryModel;
        }));
    }
  }

  updateCategory(category: SaveCategoryModel): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this._authenticationService.checkUser()) {
      return this.http
        .put(getUpdateCategoryUrl(category.id), category, httpOptions)
        .pipe(map(res => {
          const categoryModel = res as CategoryModel;
          this._userService.getUserById(Number.parseInt(categoryModel.createdById.toString(), 0))
            .subscribe(res1 => categoryModel.createdBy = res1);
          this._userService.getUserById(Number.parseInt(categoryModel.updatedById.toString(), 0))
            .subscribe(res2 => categoryModel.updatedBy = res2);
          return categoryModel;
        }));
    }
  }
}
