import { CashboxModel } from '../models/cashbox.model';
import { SaveCashboxModel } from '../models/save-cashbox.model';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppCommunicationService } from './app-communication.service';
import { UserService } from './user.service';
import {
  getCashboxActualBalanceUrl,
  getCreateCashboxUrl,
  getAllRecentCashboxUrl,
  getCreateCashboxSellUrl,
  getTodaySellsFromWorkplaceUrl,
  getCurrentUserLastSellUrl
} from '../app.urls';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../core-frontend-common/auth/authentication.service';

@Injectable()
export class CashboxService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private _userService: UserService,
    private _appCommunicationService: AppCommunicationService,
    private _authenticationService: AuthenticationService,
  ) {
  }

  getActualBalance(filter: any = null): Observable<number> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this._authenticationService.checkUser()) {
      return this.http
        .get(getCashboxActualBalanceUrl() + this.toQueryString(filter), httpOptions)
        .pipe(map(res => res as number));
    }
  }

  getCurrentUserLastSell(): Observable<CashboxModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this._authenticationService.checkUser()) {
      return this.http
        .get(getCurrentUserLastSellUrl(), httpOptions)
        .pipe(map(res => {
          const result = res as CashboxModel;
          if (result) {
            this._userService.getUserById(+result.createdById)
              .subscribe(res1 => result.createdBy = res1);
          }
          return result;
        }));
    }
  }

  createCashbox(cashbox: SaveCashboxModel): Observable<CashboxModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this._authenticationService.checkUser()) {
      return this.http
        .post(getCreateCashboxUrl(), cashbox, httpOptions)
        .pipe(map(res => {
          return res as CashboxModel;
        }));
    }
  }

  createSell(cashbox: SaveCashboxModel): Observable<CashboxModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this._authenticationService.checkUser()) {
      return this.http
        .post(getCreateCashboxSellUrl(), cashbox, httpOptions)
        .pipe(map(res => {
          return res as CashboxModel;
        }));
    }
  }

  getAllRecent(filter: any = null): Observable<CashboxModel[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this._authenticationService.checkUser()) {
      return this.http
        .get(getAllRecentCashboxUrl() + this.toQueryString(filter), httpOptions)
        .pipe(map(res => {
          return res as CashboxModel[];
        }));
    }
  }

  getTodaySellsFromWorkplace(): Observable<CashboxModel[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this._authenticationService.checkUser()) {
      return this.http
        .get(getTodaySellsFromWorkplaceUrl(), httpOptions)
        .pipe(map(res => {
          return res['items'] as CashboxModel[];
        }));
    }
  }

  private toQueryString(obj: any) {
    if (obj == null) {
      return '';
    }
    const parts = [];
    for (const property in obj) {
      const value = obj[property];
      if (value != null) {
        parts.push(encodeURIComponent(property) + '=' + encodeURIComponent(value));
      }
    }
    return '?' + parts.join('&');
  }
}
