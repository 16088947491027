import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { I18nModule } from '../core-frontend-common/template/shared/i18n/i18n.module';

import { UserProfileViewComponent } from './views/user-profile-view/user-profile-view.component';

import { AppCommunicationService } from './services/app-communication.service';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { CustomPipesModule } from './pipes/customPipes.module';
import { TileComponent } from './components/tile/tile.component';
import { UserSearchComponent } from './components/user-search/user-search.component';
import { ExternalUserModalFormComponent } from './components/external-user-modal-form/external-user-modal-form.component';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ModalModule, PopoverModule, TabsModule, TooltipModule } from 'ngx-bootstrap';
import { CalendarModule } from 'primeng/calendar';
import { DevComponent } from './components/dev/dev.component';
import { UserPipe } from './pipes/user.pipe';
import { FullCalendarModule } from 'primeng/fullcalendar';
import { TableModule } from 'primeng/table';
import { SharedModule } from '../core-frontend-common/template/shared/shared.module';
import { SmartClockpickerDirective } from '../core-frontend-common/template/shared/utils/smart-clockpicker.directive';
import { BtnGroupFilterComponent } from './components/btn-group-filter/btn-group-filter.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BaseModalDialogModule } from './components/dialog/base-modal-dialog.module';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CashDeskViewComponent } from './views/cash-desk-view/cash-desk-view.component';
import { ItemViewComponent } from './views/item-tab/item-view/item-view.component';
import { WorkplaceSelectorViewComponent } from './views/workplace-selector-view/workplace-selector-view.component';
import { ItemTabComponent } from './views/item-tab/item-tab.component';
import { CategoryViewComponent } from './views/item-tab/category-view/category-view.component';
import { ItemFormComponent } from './views/item-form/item-form.component';
import { FloatNumberFormatDirective } from './directives/floatNumberFormat.directive';
import { CategoryFormComponent } from './views/category-form/category-form.component';
import { FocusDirective } from './directives/focus.directive';
import { PluHelperComponent } from './views/cash-desk-view/plu-helper/plu-helper.component';
import { UserViewComponent } from './views/user-view/user-view.component';
import { CashHistoryComponent } from './views/cash-history/cash-history.component';
import { CashWithdrawInsertFormComponent } from './views/cash-withdraw-insert-form/cash-withdraw-insert-form.component';
import { CashboxDetailComponent } from './views/cashbox-detail/cashbox-detail.component';
import { ItemService } from './services/item.service';
import { CategoryService } from './services/category.service';
import { CashboxService } from './services/cashbox.service';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { PanelsModule } from '../core-frontend-common/template/shared/panels/panels.module';
import { NotificationComponent } from "./components/notification/notification.component";
import { ConfirmationService } from "primeng/api";

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    I18nModule,
    CustomPipesModule,
    RouterModule,
    AutoCompleteModule,
    ModalModule,
    CalendarModule,
    TooltipModule,
    FullCalendarModule,
    TableModule,
    PopoverModule,
    SharedModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxDatatableModule,
    TabsModule,
    PanelsModule,
    BaseModalDialogModule
  ],
  declarations: [
    DashboardComponent,
    UserProfileViewComponent,
    DashboardComponent,
    TileComponent,
    UserSearchComponent,
    ExternalUserModalFormComponent,
    UserPipe,
    DevComponent,
    SmartClockpickerDirective,
    BtnGroupFilterComponent,
    UserProfileViewComponent,
    CashDeskViewComponent,
    ItemViewComponent,
    WorkplaceSelectorViewComponent,
    ItemTabComponent,
    CategoryViewComponent,
    ItemFormComponent,
    FloatNumberFormatDirective,
    CategoryFormComponent,
    FocusDirective,
    PluHelperComponent,
    UserViewComponent,
    CashHistoryComponent,
    CashWithdrawInsertFormComponent,
    CashboxDetailComponent,
    DevComponent
  ],
  exports: [],
  providers: [
    AppCommunicationService,
    DeviceDetectorService,
    ItemService,
    CategoryService,
    CashboxService,
    ConfirmationService
  ],
})
export class CustomModule {
}
