import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import {CashboxModel} from '../../models/cashbox.model';

@Component({
  selector: 'app-cashbox-detail',
  templateUrl: './cashbox-detail.component.html',
  styleUrls: ['./cashbox-detail.component.scss']
})
export class CashboxDetailComponent implements OnInit {

  cashbox: CashboxModel;
  workplaceCode: string;

  // Definujem si nazov Child elementu v HTML
  @ViewChild('lgModalDetail', {static: true}) public lgModal: ModalDirective;

  constructor(
   ) { }

  ngOnInit() {
  }

  // Touto metodou zavolam modalne okno, ktore sa otvori
  showModal(inputCashbox: CashboxModel, workplaceCode: string) {

    this.workplaceCode = workplaceCode;
    this.cashbox = inputCashbox;
    // console.log(this.cashbox);

    // Otvorim modalne okno
    this.lgModal.show();
  }

  // Zavriem modalne okno a resetujem formular
  closeModal() {
    this.lgModal.hide();
  }

}
