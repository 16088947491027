import { WorkplaceModel } from '../models/workplace.model';
import { SaveItemModel } from '../models/save-item.model';
import { UserService } from './user.service';
import { Router } from '@angular/router';
import { AppCommunicationService } from './app-communication.service';
import { Injectable } from '@angular/core';
import { ItemModel } from '../models/item.model';
import {
  getItemUrl,
  getCreateItemUrl,
  getSoftDeleteItemUrl,
  getUpdateItemUrl,
  getGetAllItemsFromWorkplaceUrl,
  getWorkplaceUrl, getMinItemPriceUrl, getUpdateMinItemPriceUrl
} from "../app.urls";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../core-frontend-common/auth/authentication.service';

@Injectable()
export class ItemService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private _userService: UserService,
    private _appCommunicationService: AppCommunicationService,
    private authenticationService: AuthenticationService,
  ) {}

  // ME: Specialne som pouzil ItemService na natahovanie workplaces, pretoze je kvoli tejto jednej metode vytvarat novy service.
  // Ak sa casom nieco rozsiri, treba to presunut do vlastne WorkplaceService
  getAllWorkplaces() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this.authenticationService.checkUser()) {
      return this.http.get(getWorkplaceUrl(), httpOptions).pipe(map(res => res as WorkplaceModel[]));
    }
  }

  getAllItems(filter: any) {
    return this.getAllItemsApi(filter, false);
  }

  getAllItemsWithResponsible(filter: any) {
    return this.getAllItemsApi(filter, true);
  }

  createItem(item: SaveItemModel): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this.authenticationService.checkUser()) {
      return this.http.post(getCreateItemUrl(), item, httpOptions).pipe(
        map(res => {
          const itemModel = res as ItemModel;
          this._userService
            .getUserById(Number.parseInt(itemModel.createdById.toString(), 0))
            .subscribe(res1 => (itemModel.createdBy = res1));
          this._userService
            .getUserById(Number.parseInt(itemModel.updatedById.toString(), 0))
            .subscribe(res2 => (itemModel.updatedBy = res2));
          return itemModel;
        }),
      );
    }
  }

  updateItem(item: SaveItemModel): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this.authenticationService.checkUser()) {
      return this.http.put(getUpdateItemUrl(item.id), item, httpOptions).pipe(
        map(res => {
          const itemModel = res as ItemModel;
          this._userService
            .getUserById(Number.parseInt(itemModel.createdById.toString(), 0))
            .subscribe(res1 => (itemModel.createdBy = res1));
          this._userService
            .getUserById(Number.parseInt(itemModel.updatedById.toString(), 0))
            .subscribe(res2 => (itemModel.updatedBy = res2));
          return itemModel;
        }),
      );
    }
  }

  softDeleteItem(itemId: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this.authenticationService.checkUser()) {
      return this.http.put(getSoftDeleteItemUrl(itemId), null, httpOptions);
    }
  }

  // TODO issue #28 backend - prepinac na nacitavanie with responsible
  private getAllItemsApi(filter: any = null, includeResponsible = false): Observable<ItemModel[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this.authenticationService.checkUser()) {
      return this.http
        .get(getItemUrl() + this.toQueryString(filter), httpOptions)
        .pipe(map((res: any) => res.items as ItemModel[]));
    }
  }

  getAllItemsFromWorkplace(filter: any = null): Observable<ItemModel[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this.authenticationService.checkUser()) {
      return this.http
        .get(getGetAllItemsFromWorkplaceUrl() + this.toQueryString(filter), httpOptions)
        .pipe(map((res: any) => res.items as ItemModel[]));
    }
  }

  private toQueryString(obj: any) {
    if (obj == null) {
      return '';
    }
    const parts = [];
    for (const property in obj) {
      const value = obj[property];
      if (value !== null) {
        parts.push(`${encodeURIComponent(property)}=${encodeURIComponent(value)}`);
      }
    }
    return `?${parts.join('&')}`;
  }

  getMinItemPrice(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this.authenticationService.checkUser()) {
      return this.http.get(getMinItemPriceUrl(), httpOptions);
    }
  }

  setMinItemPrice(price: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this.authenticationService.checkUser()) {
      return this.http.post(getUpdateMinItemPriceUrl(), {Key: 'MinAllowedPrice', ValueDecimal: price}, httpOptions);
    }
  }
}
