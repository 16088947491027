import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core-frontend-common/template/core/core.module';
import { CustomModule } from './+app-custom/custom.module';
import { AuthGuard } from './core-frontend-common/auth/guards/auth.guard';
import { AuthenticationService } from './core-frontend-common/auth/authentication.service';
import { UserService } from './+app-custom/services/user.service';
import { GeneralService } from './+app-custom/services/general.service';
import { AppRedirectService } from './core-frontend-common/auth/app-redirect.service';
import { CookieService } from 'ngx-cookie-service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { RoleGuard } from './core-frontend-common/auth/guards/role.guard';
import { SharedModule } from './core-frontend-common/template/shared/shared.module';
import { ReportBugDialogModule } from './+app-custom/components/report-bug-dialog/report-bug-dialog.module';
import { AUTH_INTERCEPTOR_PROVIDER } from './core-frontend-common/auth/interceptors/auth-interceptor.provider';
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { AppThemeModule } from './+app-custom/theme/app-theme.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    CustomModule,
    SharedModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({ positionClass: "toast-top-center" }),
    ReportBugDialogModule,
    ConfirmDialogModule,
      AppThemeModule,
    // ToastrModule added
  ],
  providers: [
    AUTH_INTERCEPTOR_PROVIDER,
    AuthGuard,
    RoleGuard,
    AuthenticationService,
    UserService,
    CookieService,
    GeneralService,
    AppRedirectService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
