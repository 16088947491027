import { NgForm } from '@angular/forms';
import { CategoryService } from '../../services/category.service';
import { ModalDirective } from 'ngx-bootstrap';
import { SaveCategoryModel } from '../../models/save-category.model';
import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { AppCommunicationService } from '../../services/app-communication.service';
import { CategoryModel } from '../../models/category.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-category-form',
  templateUrl: './category-form.component.html',
  styleUrls: ['./category-form.component.scss'],
})
export class CategoryFormComponent implements OnInit {

  // Deklarujem premenne:
  saving = false;
  // category: je SaveResource backend model -> pomocou tejto struktury odoslem data na backend
  category: SaveCategoryModel;
  categories: CategoryModel[];
  matrixPositions: any[];
  inputCashboxMatrixPosition = '';
  matrixPositionsUKVS: any[];
  inputCashboxMatrixPositionUKVS = '';

  // Definujem si nazov Child elementu v HTML
  @ViewChild('lgModal', { static: false }) public lgModal: ModalDirective;
  @ViewChild('f', { static: false }) public form: NgForm;
  @Output() loadParentData = new EventEmitter();

  constructor(
    private _categoryService: CategoryService,
    private _appCommunicationService: AppCommunicationService,
    private toastr: ToastrService,
  ) {
  }

  ngOnInit() {
    // Pri inicializacii komponentu nastavim defaultne hodnoty v modeli
    this.setDefaultCategoryData();
  }

  // Touto metodou zavolam modalne okno, ktore sa otvori
  showModal(inputCategory: CategoryModel) {
    this.saving = false;
    // Ak editujem existuji item, tak ho namapujem do modelu, inak nastavim defaultne hodnoty
    if (inputCategory) {
      this.mapExistingItemIntoModel(inputCategory);
    } else {
      this.setDefaultCategoryData();
    }

    this._categoryService.getAllCategories()
      .subscribe(res => {
          this.categories = res;
          this.setAvailableMatrixPositions();
          this.setAvailableMatrixPositionsUKVS();
        },
        error2 => {
          console.log(error2);
          this.closeModal();
        });

    // Otvorim modalne okno
    this.lgModal.show();
  }

  // Zavriem modalne okno a resetujem formular
  closeModal() {
    this.saving = false;
    this.form.resetForm();
    this.lgModal.hide();
  }

  // Potvrdenie
  confirmModal() {
    this.saving = true;
    // Vytvorim objekt alebo ulozim zmeny, podla toho, ci item.id = 0 alebo uz idcko ma
    if (this.category.id) {
      this._categoryService.updateCategory(this.category)
        .subscribe(item => {
          this.closeModal();
          this.loadParentData.emit();
          this.toastr.success('Kategória' +
            this.category.name + 'bola úspešne upravená.', 'Úprava');
        }, error2 => {
          this.closeModal();
          console.log(error2);
          this.toastr.error('Nepodarilo sa upraviť kategóriu. Kontaktujte prosím podporu.', 'Úprava neúspešná');
        });
    } else {
      this._categoryService.createCategory(this.category)
        .subscribe(item => {
          this.closeModal();
          this.loadParentData.emit();
          this.toastr.success('Kategória' +
            this.category.name + 'bola úspešne vytvorená.', 'Vytvorenie');
        }, error2 => {
          this.closeModal();
          console.log(error2);
          this.toastr.error('Nepodarilo sa vytvoriť kategóriu. Kontaktujte prosím podporu.', 'Vytvorenie neúspešné');
        });
    }
  }

  private setDefaultCategoryData() {
    // Aby som pri nacitani nastavil defaultne/nulove hodnoty tak, aby bol formular prazdny, aby DOM nacitalo bez chyb a pod.
    this.category = {
      id: 0,
      name: '',
      cashboxMatrixPosition: '',
      cashboxMatrixPositionUKVS: '',
    };
  }

  // Existujuci item namapujem do modelu
  private mapExistingItemIntoModel(inputCategory: CategoryModel) {
    this.category.id = inputCategory.id;
    this.category.name = inputCategory.name;
    this.category.cashboxMatrixPosition = inputCategory.cashboxMatrixPosition;
    this.inputCashboxMatrixPosition = inputCategory.cashboxMatrixPosition;
    this.category.cashboxMatrixPositionUKVS = inputCategory.cashboxMatrixPositionUKVS;
    this.inputCashboxMatrixPositionUKVS = inputCategory.cashboxMatrixPositionUKVS;
  }

  setAvailableMatrixPositions() {
    this.matrixPositions = [
      'firstColumnTop',
      'firstColumnMiddle',
      'firstColumnBottom',

      'secondColumnTop',
      'secondColumnMiddle',
      'secondColumnBottom',

      'thirdColumnTop',
      'thirdColumnMiddle',
      'thirdColumnBottom',

      'fourthColumnTop',
      'fourthColumnMiddle',
      'fourthColumnBottom',

      'firstbottomRow',
      'lastbottomRow',
    ];

    this.categories.forEach(category => {
      const index = this.matrixPositions.indexOf(category.cashboxMatrixPosition);
      if (category.cashboxMatrixPosition != null &&
        category.cashboxMatrixPosition !== '' &&
        this.category.cashboxMatrixPosition !== category.cashboxMatrixPosition &&
        this.inputCashboxMatrixPosition !== category.cashboxMatrixPosition) {
        this.matrixPositions.splice(index, 1);
      }
    });
  }

  setAvailableMatrixPositionsUKVS() {
    this.matrixPositionsUKVS = [
      'firstColumnTop',
      'firstColumnMiddle',
      'firstColumnBottom',

      'secondColumnTop',
      'secondColumnMiddle',
      'secondColumnBottom',

      'thirdColumnTop',
      'thirdColumnMiddle',
      'thirdColumnBottom',

      'fourthColumnTop',
      'fourthColumnMiddle',
      'fourthColumnBottom',

      'firstbottomRow',
      'lastbottomRow',
    ];

    this.categories.forEach(category => {
      const index = this.matrixPositionsUKVS.indexOf(category.cashboxMatrixPositionUKVS);
      // console.log(category.cashboxMatrixPositionUKVS,
      // index, category.cashboxMatrixPositionUKVS != null, category.cashboxMatrixPositionUKVS != "");
      if (category.cashboxMatrixPositionUKVS !== null &&
        category.cashboxMatrixPositionUKVS !== '' &&
        this.category.cashboxMatrixPositionUKVS !== category.cashboxMatrixPositionUKVS &&
        this.inputCashboxMatrixPositionUKVS !== category.cashboxMatrixPositionUKVS) {
        this.matrixPositionsUKVS.splice(index, 1);
        // console.log("del")
      }
    });
  }

  getMatrixPosition(position: string) {
    switch (position) {
      case 'firstColumnTop': {
        return 'Prvý stĺpec hore';
      }
      case 'firstColumnMiddle': {
        return 'Prvý stĺpec v strede';
      }
      case 'firstColumnBottom': {
        return 'Prvý stĺpec dole';
      }
      case 'secondColumnTop': {
        return 'Druhý stĺpec hore';
      }
      case 'secondColumnMiddle': {
        return 'Druhý stĺpec v strede';
      }
      case 'secondColumnBottom': {
        return 'Druhý stĺpec dole';
      }
      case 'thirdColumnTop': {
        return 'Tretí stĺpec hore';
      }
      case 'thirdColumnMiddle': {
        return 'Tretí stĺpec v strede';
      }
      case 'thirdColumnBottom': {
        return 'Tretí stĺpec dole';
      }
      case 'fourthColumnTop': {
        return 'Štvrtý stĺpec hore';
      }
      case 'fourthColumnMiddle': {
        return 'Štvrtý stĺpec v strede';
      }
      case 'fourthColumnBottom': {
        return 'Štvrtý stĺpec dole';
      }
      case 'firstbottomRow': {
        return 'Spodný riadok hore';
      }
      case 'lastbottomRow': {
        return 'Spodný riadok dole';
      }
      default: {
        return '- bez pozície -';
      }
    }
  }
}
