import { Router } from '@angular/router';
import { CategoryService } from '../../../services/category.service';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent, ColumnMode } from '@swimlane/ngx-datatable';
import { AppCommunicationService } from '../../../services/app-communication.service';
import { CategoryModel } from '../../../models/category.model';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-category-view',
  templateUrl: './category-view.component.html',
  styleUrls: ['./category-view.component.scss']
})
export class CategoryViewComponent implements OnInit, AfterViewInit {
  private readonly PAGE_SIZE = 100;

  isUserAdmin = false;

  categories: CategoryModel[] = [];
  selectedRow: CategoryModel = undefined;

  /* ### NGX Datatable /events/ settings ### */
  loadingIndicator = true;

  controls: any = {
      pageSize: 100,
      filter: '',
  };

  ngAfterViewInit() {
  }

  constructor(
    private _categoryService: CategoryService,
    private router: Router,
    private _appCommunicationService: AppCommunicationService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    // Pri inicializacii komponentu nacitam potrebne data
    this.loadData();
  }

  // nacitanie dat
  public loadData() {
    this.loadingIndicator = true;
    this._categoryService.getAllCategoriesWithResponsibles()
    .subscribe(
      res => {
          this.categories = res;
          this.loadingIndicator = false;
        },
      error2 => {
          console.log(error2);
          this.loadingIndicator = false;
        }
    );
  }

  getMatrixPosition(position: string) {
    switch (position) {
      case 'firstColumnTop': {
        return 'Prvý stĺpec hore';
      }
      case 'firstColumnMiddle': {
        return 'Prvý stĺpec v strede';
      }
      case 'firstColumnBottom': {
        return 'Prvý stĺpec dole';
      }
      case 'secondColumnTop': {
        return 'Druhý stĺpec hore';
      }
      case 'secondColumnMiddle': {
        return 'Druhý stĺpec v strede';
      }
      case 'secondColumnBottom': {
        return 'Druhý stĺpec dole';
      }
      case 'thirdColumnTop': {
        return 'Tretí stĺpec hore';
      }
      case 'thirdColumnMiddle': {
        return 'Tretí stĺpec v strede';
      }
      case 'thirdColumnBottom': {
        return 'Tretí stĺpec dole';
      }
      case 'fourthColumnTop': {
        return 'Štvrtý stĺpec hore';
      }
      case 'fourthColumnMiddle': {
        return 'Štvrtý stĺpec v strede';
      }
      case 'fourthColumnBottom': {
        return 'Štvrtý stĺpec dole';
      }
      case 'firstbottomRow': {
        return 'Spodný riadok hore';
      }
      case 'lastbottomRow': {
        return 'Spodný riadok dole';
      }
      default: {
        return '- bez pozície -';
      }
   }
  }

  // Zmazem item vyvolanim dialogoveho okna s otazkou, v pripade suhlasu, volam mazanie z item service.
  // V oboch pripadoch zobrazim hlasku.
  public deleteCategory(category: CategoryModel) {
    // this.notificationService.smartMessageBox({
    //   title: 'Odstránenie',
    //   content: 'Naozaj chcete odstrániť kategóriu \'' + category.name + '\'?',
    //   buttons: '[Áno][Nie]'
    // }, (ButtonPressed) => {
    //   if (ButtonPressed === 'Áno') {
    //     this._categoryService.softDeleteCategory(category.id)
    //         .subscribe(
    //           res => {
    //             this.loadData();
    //             this.notificationService.smallBox({
    //               title: 'Ostránenie',
    //               content: '<i class=\'fa fa-chevron-right\'></i> <i>Kategória \'' + category.name + '\' bola odstránená.</i>',
    //               color: '#659265',
    //               iconSmall: 'fa fa-check fa-2x fadeInRight animated',
    //               timeout: 4000
    //             });
    //           },
    //           error2 => {
    //               console.log(error2);
    //           }
    //         );
    //   }
    //
    //   if (ButtonPressed === 'Nie') {
    //     this.notificationService.smallBox({
    //       title: 'Ostránenie zrušené',
    //       content: '<i class=\'fa fa-chevron-right\'></i> <i>Kategória nebola odstránená.</i>',
    //       color: '#C46A69',
    //       iconSmall: 'fa fa-times fa-2x fadeInRight animated',
    //       timeout: 4000
    //     });
    //   }
    // });
  }
}
