import { Router } from '@angular/router';
import { ItemModel } from '../../../models/item.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { AppCommunicationService } from '../../../services/app-communication.service';
import { ItemService } from '../../../services/item.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-item-view',
  templateUrl: './item-view.component.html',
  styleUrls: ['./item-view.component.scss']
})
export class ItemViewComponent implements OnInit {
  private readonly PAGE_SIZE = 500;
  query: any = {
    pageSize: this.PAGE_SIZE,
    page: 1,
    searchByName: null
  };

  items: ItemModel[] = [];
  selectedRow: ItemModel = undefined;

  showSetMinPrice = false;
  minPrice = 0;

  /* ### NGX Datatable /events/ settings ### */
  loadingIndicator = true;
  controls: any = {
    pageSize: this.PAGE_SIZE,
    filter: ''
  };

  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;

  constructor(
    private _itemService: ItemService,
    private router: Router,
    private _appCommunicationService: AppCommunicationService,
    private toastr: ToastrService,
    private confirmService: ConfirmationService
  ) {
  }

  isUserAdmin = false;

  ngOnInit() {
    this.loadData();
  }

  public loadData() {
    this.loadingIndicator = true;


    this._itemService.getMinItemPrice().pipe(first())
      .subscribe({
        next: (data) => {
          if (data && data.valueDecimal) {
            this.minPrice = data.valueDecimal;
          }
        }
      });

    if (this.isUserAdmin) {
      this._itemService.getAllItems(this.query).subscribe(
        res => {
          this.items = res;
          this.loadingIndicator = false;
        },
        error2 => {
          console.log(error2);
          this.loadingIndicator = false;
        }
      );
    } else {
      this._itemService.getAllItemsFromWorkplace(this.query).subscribe(
        res => {
          this.items = res;
          this.loadingIndicator = false;
        },
        error2 => {
          console.log(error2);
          this.loadingIndicator = false;
        }
      );
    }
  }

  public onSearchChange() {
    setTimeout(() => this.loadData(), 700);
    this.query.page = 1;
  }

  public getWorkplaceCodeFormated(workplaceCode: string) {
    return workplaceCode.replace('UIK_', '');
  }

  submitMinPrice() {
    this._itemService
      .setMinItemPrice(this.minPrice)
      .pipe(first())
      .subscribe(val => {
        this.minPrice = val.valueDecimal || 0;
        this.showSetMinPrice = false;
        this.toastr.success('Nastavenie minimálnej ceny prebehlo úspešne.');
      }, error => {
        console.log(error);
        this.toastr.error('Nastavenie minimálnej ceny zlyhalo.');
      });
  }

  // Zmazem item vyvolanim dialogoveho okna s otazkou, v pripade suhlasu, volam mazanie z item service.
  // V oboch pripadoch zobrazim hlasku.
  public deleteItem(item: ItemModel) {

    this.confirmService.confirm({
      header: 'Odstránenie položky',
      message: 'Naozaj chcete odstrániť položku \'' + item.name + '\'?',
      acceptLabel: 'Áno',
      rejectLabel: 'Nie',
      accept: () => {
        this._itemService.softDeleteItem(item.id)
          .subscribe(
            res => {
              this.loadData();

              this.toastr.success(`Položka ${item.name} bola odstránená.`);
            },
            error2 => {
              console.log(error2);
            }
          );
      }
    });
  }
}
