import { NavigationItem } from './core-frontend-common/template/store/navigation';
import {SYSTEM_NAME_AUTH, UserRoles} from './+app-custom/constants';
import { getBaseFrontendUrl } from './+app-custom/app.urls';

export const NavigationItems: NavigationItem[] = [
  {
    title: 'modules',
    tags: 'modules',
    icon: 'fal fa-angle-down',
    routerLink: 'null',
    externalUrl: `${getBaseFrontendUrl(SYSTEM_NAME_AUTH)}`,
    roles: UserRoles.All,
  },
  {
    title: 'cashDesk',
    tags: 'application intel',
    icon: 'fal fa-money-bill',
    routerLink: '/cashDesk',
    roles: UserRoles.All,
  },
  {
    title: 'Položky',
    tags: 'application intel',
    icon: 'fal fa-list',
    routerLink: '/items',
    roles: UserRoles.All,
  },
  {
    title: 'Zamestnanci',
    tags: 'application intel',
    icon: 'fal fa-users',
    routerLink: '/users',
    roles: UserRoles.Admins,
  },
  {
    title: 'Záznamy predajov',
    tags: 'application intel',
    icon: 'fal fa-history',
    routerLink: '/cashHistory',
    roles: UserRoles.All,
  },
];
