import { CashboxService } from '../../services/cashbox.service';
import { SaveCashboxModel } from '../../models/save-cashbox.model';
import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { NgForm } from '@angular/forms';
import { AppCommunicationService } from '../../services/app-communication.service';
import { ToastrService } from 'ngx-toastr';
import { ConstantsCashboxType } from '../../constants';

@Component({
  selector: 'app-cash-withdraw-insert-form',
  templateUrl: './cash-withdraw-insert-form.component.html',
  styleUrls: ['./cash-withdraw-insert-form.component.scss'],
})
export class CashWithdrawInsertFormComponent implements OnInit {
  workplaceCode: string;
  // Deklarujem premenne:
  saving = false;
  // cashbox: je SaveResource backend model -> pomocou tejto struktury odoslem data na backend
  cashbox: SaveCashboxModel;

  // Definujem si nazov Child elementu v HTML
  @ViewChild('lgModal', { static: false }) public lgModal: ModalDirective;
  @ViewChild('f', { static: false }) public form: NgForm;

  // Definujem, co sa stane na vystupe -> vytvorim event, ktory zavola v
  // rodicovskom/parent componente metodu -> ta znovu nacita data v parente
  @Output() loadParentData = new EventEmitter();

  constructor(
    private _cashboxService: CashboxService,
    private toastr: ToastrService,
    private _appCommunicationService: AppCommunicationService,
  ) {
  }

  ngOnInit() {
    // Pri inicializacii komponentu nastavim defaultne hodnoty v modeli
    this.setDefaultCashboxData();
  }

  // Touto metodou zavolam modalne okno, ktore sa otvori
  showModal(workplaceCode: any = null) {
    this.saving = false;
    if (workplaceCode) {
      this.workplaceCode = workplaceCode;
    } else {
      this.workplaceCode = AppCommunicationService.getWorkplaceFromLocalStorage();
    }

    this.setDefaultCashboxData();

    // Otvorim modalne okno
    this.lgModal.show();
  }

  // Zavriem modalne okno a resetujem formular
  closeModal() {
    this.saving = false;
    this.form.resetForm();
    this.lgModal.hide();
  }

  // Potvrdenie
  confirmModal() {
    this.saving = true;
    let messageText = '';
    let title = '';
    this._cashboxService.createCashbox(this.cashbox)
      .subscribe(cashbox => {
          this.closeModal();
          this.loadParentData.emit();

          if (cashbox.type === ConstantsCashboxType.CASHBOX_TYPE_WITHDRAWAL) {
            messageText = '<i class=\'fa fa-chevron-right\'></i> <i>Výber z kasy v sume \'' + cashbox.price + ' €\' bol úspešne vykonaný.</i>';
            title = 'Vykonaný výber';
          } else if (cashbox.type === ConstantsCashboxType.CASHBOX_TYPE_DEPOSIT) {
            messageText = '<i class=\'fa fa-chevron-right\'></i> <i>Vklad do kasy v sume \'' + cashbox.price + ' €\' bol úspešne vykonaný.</i>';
            title = 'Vykonaný vklad';
          } else {
            messageText = '<i class=\'fa fa-chevron-right\'></i> <i>Priame zadanie stavu kasy v sume \'' + cashbox.price + ' €\' bolo úspešne vykonané.</i>';
            title = 'Vykonané priame zadanie';
          }
          this.toastr.success(messageText, title);
        }
        , error2 => {
          if (this.cashbox.type === ConstantsCashboxType.CASHBOX_TYPE_WITHDRAWAL) {
            messageText = '<i class=\'fa fa-chevron-right\'></i> <i>Nepodarilo sa vykonať výber. Kontaktujte prosím podporu.</i>';
            title = 'Výber neúspešný';
          } else if (this.cashbox.type === ConstantsCashboxType.CASHBOX_TYPE_DEPOSIT) {
            messageText = '<i class=\'fa fa-chevron-right\'></i> <i>Nepodarilo sa vykonať vklad. Kontaktujte prosím podporu.</i>';
            title = 'Vklad neúspešný';
          } else {
            messageText = '<i class=\'fa fa-chevron-right\'></i> <i>Nepodarilo sa vykonať priame zadanie stavu. Kontaktujte prosím podporu.</i>';
            title = 'Priame zadanie neúspešné';
          }

          this.lgModal.hide();
          console.log(error2);
          this.toastr.error(messageText, title);
        });
  }

  private setDefaultCashboxData() {
    // Aby som pri nacitani nastavil defaultne/nulove hodnoty tak, aby bol formular prazdny, aby DOM nacitalo bez chyb a pod.
    this.cashbox = {
      id: 0,
      type: ConstantsCashboxType.CASHBOX_TYPE_WITHDRAWAL,
      price: null,
      employeeDiscount: null,
      items: [],
      workplaceCode: this.workplaceCode,
    };
  }

}
