import { AppCommunicationService } from '../../services/app-communication.service';
import { CashboxModel } from '../../models/cashbox.model';
import { CashboxService } from '../../services/cashbox.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-cash-history',
  templateUrl: './cash-history.component.html',
  styleUrls: ['./cash-history.component.scss']
})
export class CashHistoryComponent implements OnInit {
  private readonly PAGE_SIZE = 1000;

  isUserAdmin = false;

  cashboxRecords: CashboxModel[] = [];
  timeout: any;
  loadingIndicator = true;
  controls: any = {
    pageSize: this.PAGE_SIZE,
    filter: ''
  };
  workplaceCode: string = AppCommunicationService.getWorkplaceFromLocalStorage();
  actualBalance = 0;
  buttonFilter = '1';

  query: any = {
    pageSize: this.PAGE_SIZE,
    page: 1,
    searchByName: null,
    workplaceCode: this.workplaceCode,
    isToday: true
  };

  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;

  constructor(
    private _cashboxService: CashboxService,
    private _appCommunicationService: AppCommunicationService
  ) {
  }

  ngOnInit() {
    this.isUserAdmin = this._appCommunicationService.isUserAdmin();
    this.workplaceCode = AppCommunicationService.getWorkplaceFromLocalStorage();
    this.loadData();

    this._appCommunicationService
      .isWorkplaceChanged
      .subscribe(() => {
        this.workplaceCode = AppCommunicationService.getWorkplaceFromLocalStorage();
        this.loadData();
      });
  }

  onSelectButtonFilter(filter: string) {
    this.buttonFilter = filter;
    this.loadData();
  }

  loadData() {
    this.loadingIndicator = true;
    this.cashboxRecords = [];
    this.actualBalance = 0;
    this.query.workplaceCode = this.workplaceCode;

    this._cashboxService.getActualBalance(this.query)
      .subscribe(res => {
          this.actualBalance = res;
        },
        error2 => {
          console.log(error2);
        });

    if (this.isUserAdmin) {
      this.query.isToday = this.buttonFilter === '1';

      this._cashboxService.getAllRecent(this.query)
        .subscribe(
          (res: any) => {
            this.cashboxRecords = res.items;
            this.loadingIndicator = false;
          },
          error2 => {
            console.log(error2);
            this.loadingIndicator = false;
          }
        );
    } else {
      this.query.isToday = true;
      this._cashboxService.getTodaySellsFromWorkplace()
        .subscribe(
          res => {
            this.cashboxRecords = res;
            // console.log(this.cashboxRecords);
            this.loadingIndicator = false;
          },
          error2 => {
            this.loadingIndicator = false;
            console.log(error2);
          }
        );
    }
  }

  onPage(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
    }, 100);
  }
}
