import { Component, OnInit, Renderer2 } from '@angular/core';
import { AppCommunicationService } from '../../services/app-communication.service';
import { AuthenticationService } from '../../../core-frontend-common/auth/authentication.service';
import { UserService } from '../../services/user.service';
import { TileModel } from '../../components/tile/tile.model';

@Component({
  selector: 'app-workplace-selector-view',
  templateUrl: './workplace-selector-view.component.html',
  styleUrls: ['./workplace-selector-view.component.scss'],
})
export class WorkplaceSelectorViewComponent implements OnInit {
  showWorkplaceSelector = true;
  menuItems:TileModel[] = [
    {
      title: 'UIK Jedlíkova 5',
      imageUrl: 'assets/img/custom/uik/j5a.png',
      redirectUrlAfterClick: null,
      route: 'UIK_J5',
    },
    {
      title: 'UK Copycentrum',
      imageUrl: 'assets/img/custom/uik/uka.png',
      redirectUrlAfterClick: null,
      route: 'UIK_UK',
    },
    {
      title: 'UK Veľkoplošné služby',
      imageUrl: 'assets/img/custom/uik/ukvs.png',
      redirectUrlAfterClick: null,
      route: 'UIK_UKVS',
    },
  ];

  constructor(
    private renderer: Renderer2,
    private appCommunicationService: AppCommunicationService,
    private authenticationService: AuthenticationService,
    protected userService: UserService
  ) {
  }

  ngOnInit() {
    this.appCommunicationService
      .isWorkplaceChanged
      .subscribe(val => {
        if (!val && !AppCommunicationService.getWorkplaceFromLocalStorage()) {
          this.showWorkplaceSelector = true;
        }
      });

    if (AppCommunicationService.getWorkplaceFromLocalStorage()) {
      this.showWorkplaceSelector = false;
    } else {
      this.renderer.setStyle(document.body, 'overflow', 'hidden');
    }
  }

  onMenuButtonClick(option: string) {
    this.userService.changeWorkplace(option).subscribe(user => {
      this.appCommunicationService.setWorkplaceToLocalStorage(user.workplaceCode.toString());
    }, error2 => {
      console.log(error2);
    });

    this.appCommunicationService.deleteWorkplaceFromLocalStorage();
    this.showWorkplaceSelector = false;
    this.appCommunicationService.setWorkplaceToLocalStorage(option);
    this.renderer.setStyle(document.body, 'overflow', 'auto');
  }
}
