import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-plu-helper',
  templateUrl: './plu-helper.component.html',
  styleUrls: ['./plu-helper.component.scss']
})
export class PluHelperComponent implements OnInit {

  @Input() plu: any[];

  constructor() { }

  ngOnInit() {
  }
}
