import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './core-frontend-common/template/shared/layout/main/main.component';
import { LayoutModule } from './core-frontend-common/template/shared/layout/layout.module';
import { UserProfileViewComponent } from './+app-custom/views/user-profile-view/user-profile-view.component';
import { AuthGuard } from './core-frontend-common/auth/guards/auth.guard';
import { DevComponent } from './+app-custom/components/dev/dev.component';
import { WorkplaceSelectorViewComponent } from './+app-custom/views/workplace-selector-view/workplace-selector-view.component';
import { CashDeskViewComponent } from './+app-custom/views/cash-desk-view/cash-desk-view.component';
import { ItemTabComponent } from './+app-custom/views/item-tab/item-tab.component';
import { UserViewComponent } from './+app-custom/views/user-view/user-view.component';
import { CashHistoryComponent } from './+app-custom/views/cash-history/cash-history.component';
import { DashboardComponent } from './+app-custom/views/dashboard/dashboard.component';

const routes: Routes = [
  { path: '', redirectTo: 'cashDesk', pathMatch: 'full', canActivate: [AuthGuard] },
  {
    path: '',
    component: WorkplaceSelectorViewComponent,
    children: [
      {
        path: '', component: MainComponent, children: [
          { path: '', redirectTo: 'cashDesk', pathMatch: 'full' },
          { path: 'dashboard', component: DashboardComponent, pathMatch: 'full' },
          { path: 'dev', component: DevComponent, pathMatch: 'full' },
          { path: 'profile', component: UserProfileViewComponent, data: { pageTitle: 'profile' }, pathMatch: 'full' },
          { path: 'cashDesk', component: CashDeskViewComponent, data: { pageTitle: 'cashDesk' }, pathMatch: 'full' },
          { path: 'items', component: ItemTabComponent, data: { pageTitle: 'Položky' } },
          { path: 'users', component: UserViewComponent, data: { pageTitle: 'Zamestnanci' }, pathMatch: 'full' },
          { path: 'cashHistory', component: CashHistoryComponent, data: { pageTitle: 'Stav kasy' }, pathMatch: 'full' },
        ],
      },
    ],
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [LayoutModule, RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
